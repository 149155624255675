import React from "react";
import "./NavBar.css";
import {useHistory} from "react-router-dom";


export default function NavBar() {

    const history = useHistory();


    function handleNavOpen() {
        const burger = document.querySelector(".burger");
        const nav = document.querySelector(".nav-items");
        const navLinks = document.querySelectorAll(".nav-items li");

        nav.classList.toggle("nav-active");

        navLinks.forEach((link) => {
            console.log(link)
            if (link.style.animation) {
                link.style.animation = '';
            } else {
                link.style.animation = `navLinkFade 0.5s ease forwards 0.5s`;
            }
        })
        burger.classList.toggle('toggle')

    }

    return <>
        <nav id={"nav-bar"}>
            <img src={process.env.PUBLIC_URL + '/android-chrome-192x192.png'} alt={"Zircon Logo"}/>
            <ul className={"nav-items"}>
                <li>
                    <button onClick={() => {
                        history.push("/")
                        handleNavOpen();
                    }}>Home
                    </button>
                </li>
                <li>
                    <button onClick={() => {
                        history.push("/aboutus")
                        handleNavOpen();
                    }}>About Us
                    </button>
                </li>
                <li>
                    <button onClick={() => {
                        history.push("/contactus")
                        handleNavOpen();
                    }}>Contact Us
                    </button>
                </li>
                {/*<li>*/}
                {/*    <a href={"/whyethanol"}>Why Ethanol</a>*/}
                {/*</li>*/}
            </ul>
            <div className={"burger"} onClick={handleNavOpen}>
                <div className={"line1"}/>
                <div className={"line2"}/>
                <div className={"line3"}/>
            </div>
        </nav>

    </>
}