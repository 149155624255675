import React, {useEffect} from "react";
import TopSection from "./TopSection/TopSection";
import AddressContainer from "./AddressContainer/AddressContainer";
import MapsSection from "./MapsSection/MapsSection";
import HelpYouOutContainer from "./HelpYouOutContainer/HelpYouOutContainer";

export default function ContactUsPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return <>
        <TopSection/>
        <AddressContainer/>
        <MapsSection/>
        <HelpYouOutContainer/>
    </>
}