import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {motion} from "framer-motion";
import "./LoadingAnimation.css";

const LoadingAnimationMainDiv = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all ease-in-out 0.3s;

  p {
    width: fit-content;
    font-weight: bold;
    font-size: 45px;
    line-height: 50px;
    color: #053623;
    margin-top: 10px;
    text-align: center;
  }


  @media (max-width: 768px) {
    p {
      font-size: 30px;
      line-height: 35px;
    }
  }

  @media (max-width: 580px) {
    p {
      font-size: 25px;
      line-height: 30px;
    }
  }

  @media (max-width: 250px) {
    p {
      font-size: 20px;
      line-height: 25px;
    }
  }

`

const LogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: relative;

  svg {
    transform: scale(0.75);
  }

  img {
    position: absolute;
    filter: drop-shadow(0px 15px 4px rgba(94, 131, 166, 0.75));
    backdrop-filter: blur(4px);
    transform: scale(2);
    animation: glow 1s infinite alternate;
  }


  @keyframes glow {
    from {
      filter: drop-shadow(0px 15px 15px rgba(94, 131, 166, 0.75));
    }
    to {
      filter: drop-shadow(0px 15px 25px rgba(94, 131, 166, 0.75));
    }
  }

  @media (max-width: 768px) {
    svg {
      transform: scale(0.5);
    }

    img {
      transform: scale(1.3);
    }
  }

  @media (max-width: 350px) {
    svg {
      transform: scale(0.4);
    }

    img {
      transform: scale(1);
    }
  }

  @media (max-width: 200px) {
    svg {
      transform: scale(0.3);
    }

    img {
      transform: scale(0.8);
    }
  }

`

export default function LoadingAnimation() {

    const zaftransition = {duration: 3, ease: "easeInOut"}
    const droptransition = {duration: 2, ease: "easeInOut"}
    const outlineTransition = {duration: 1, ease: "easeInOut", delay: 3}
    const imageTransition = {duration: 2, ease: "easeInOut", delay: 3};

    const [time, setTime] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            console.log(time)
            if (time === 4) {
                document.getElementById("loading-id").style.visibility = "hidden";
                document.getElementById("zircon-logo-image").style.visibility = "hidden";
                document.getElementById("loading-id").style.height = "0";
                clearInterval(intervalId);
            } else {
                setTime(time + 1);
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [time]);


    return <LoadingAnimationMainDiv id={"loading-id"}>

        <LogoDiv>
            <motion.svg width="400" height="310" viewBox="0 0 400 310" fill="none" xmlns="http://www.w3.org/2000/svg"
                        initial={{opacity: 1}}
                        animate={{opacity: 0}}
                        transition={outlineTransition}>
                <motion.path
                    d="M0.998718 309.5L396.997 308.485M53 275.5H348M294.037 165.965L349.054 275.975M52.5528 275.776L107.56 165.762M1.34651 309.674L72.4658 167.466M325.447 166.776L396.566 308.984M85.9468 133.478L135.149 35.0952M120.993 133.516L187.64 0.252214M209.888 0.252197L276.534 133.516M260.841 34.0748L310.043 132.458M310 132.5H355M354.5 166V133M355 166.5H325M73 166.5H43M42.5 167V134M42 133.5H87M121 133.5H222M107 165.5H210M272 133.5H277M281 166.5H294M136 34.4849H11M386 34.5H261M12.149 34.5804L29.1461 0.593617M369.447 0.776367L386.444 34.7631M27.9943 0.542005H187.994M210 0.500013H370.003M209.244 166.347L221.215 133.458M272.482 133.868L281.482 166.868"
                    stroke="black"
                    strokeWidth={2}
                    initial={{pathLength: 0}}
                    animate={{pathLength: 1}}
                    transition={zaftransition}
                />
                <motion.path
                    d="M230.506 158.024C233.447 143.912 242.117 130.252 249.024 121.761C252.869 125.962 256.936 132.101 260.37 138.608C263.91 145.315 266.757 152.372 268 158.054C267.969 170.397 257.325 177.5 249 177.5C240.736 177.5 232.006 171.243 230.506 158.024Z"
                    stroke="black"
                    strokeWidth={2}
                    initial={{pathLength: 0}}
                    animate={{pathLength: 1}}
                    transition={droptransition}/>
            </motion.svg>

            <motion.img id={"zircon-logo-image"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={imageTransition}
                        src={process.env.PUBLIC_URL + '/zirconLogo.svg'}
                        alt={"Zircon Logo"}/>

        </LogoDiv>

        {/*<motion.p*/}
        {/*    variants={companyNameTransition}*/}
        {/*    initial={"hidden"}*/}
        {/*    animate={"visible"}>*/}
        {/*    {*/}
        {/*        companyName.split(" ").map((word, index) => {*/}
        {/*            return <motion.span key={index}>{word} </motion.span>*/}
        {/*        })*/}
        {/*    }*/}
        {/*    */}
        {/*</motion.p>*/}


        <p className="slide-right">
            Zircon Advance Fuels<br/>Private Limited
            {/*{*/}
            {/*    companyName.split(" ").map((word, index) => {*/}
            {/*        return <span key={index}>{word + " "}</span>*/}
            {/*    })*/}
            {/*}*/}

        </p>


    </LoadingAnimationMainDiv>
}