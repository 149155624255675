import React, {lazy, Suspense, useEffect} from "react";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

const FirstSection = lazy(() => import('./FirstSection/FirstSection'));
const WhatWeDoSection = lazy(() => import('./WhatWeDoSection/WhatWeDoSection'));
const OurProductsSection = lazy(() => import('./OurProductsSection/OurProductsSection'));
const WeAlsoProduceSection = lazy(() => import('./WeAlsoProduceSection/WeAlsoProduceSection'));
const RawMaterialSection = lazy(() => import('./RawMaterialSection/RawMaterialSection'));


export default function LandingPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return <>
        <div>
            {/*<Suspense fallback={<div>Loading...</div>}>*/}
            {/*    <FirstSection/>*/}
            {/*</Suspense>*/}
            {/*<Suspense fallback={<div>Loading...</div>}>*/}
            {/*    <WhatWeDoSection/>*/}
            {/*</Suspense>*/}
            {/*<Suspense fallback={<div>Loading...</div>}>*/}
            {/*    <OurProductsSection/>*/}
            {/*</Suspense>*/}
            {/*<Suspense fallback={<div>Loading...</div>}>*/}
            {/*    <WeAlsoProduceSection/>*/}
            {/*</Suspense>*/}
            {/*<Suspense fallback={<div>Loading...</div>}>*/}
            {/*    <RawMaterialSection/>*/}
            {/*</Suspense>*/}

            <Suspense fallback={<LoadingAnimation/>}>
                <FirstSection/>
                <WhatWeDoSection/>
                <OurProductsSection/>
                <WeAlsoProduceSection/>
                <RawMaterialSection/>
            </Suspense>
        </div>
    </>
}

