import React, {useEffect} from "react";
import TopSection from "./TopSection/TopSection";
// import DirectorsSection from "./DirectorsSection/DirectorsSection";
import NeedForProject from "./NeedForProject/NeedForProject";
import ProjectBackground from "./ProjectBackground/ProjectBackground";

export default function AboutUsPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return <>
        <div style={{background: "#F7F7F7"}}>
            <TopSection/>
{/*             <DirectorsSection/> */}
            <NeedForProject/>
            <ProjectBackground/>
            {/*<Conclusion/>*/}
        </div>
    </>
}
