import React, {useEffect} from "react";
import "./NeedForProject.css";
import {useInView} from "react-intersection-observer";
import useScrollDirection from "../../useScrollDirection";

export default function NeedForProject() {

    const {scrollUp, scrollDown} = useScrollDirection();
    const {ref, inView} = useInView();

    useEffect(() => {
        if (inView && scrollDown) {
            document.getElementById("need-for-project-info").classList.add("active")
            document.getElementById("circle-dot-need").classList.add("active")
        } else if (inView && scrollUp) {
            document.getElementById("need-for-project-info").classList.remove("active")
            document.getElementById("circle-dot-need").classList.remove("active")
        }
    }, [inView, scrollDown, scrollUp])

    return <>
        <div className="need-for-project-main-container">
            <div id={"why-we-felt-heading-container"}>
                <span className={"why-we-felt"}>Why We Felt the Need</span>
                <span className={"why-we-felt"}>for the Project?</span>
            </div>
            <div id={"need-for-project-info-container"}>
                <div className={"need-background-div"}
                     id={"need-for-project-info"}
                     ref={ref}>
                    <div className={"circle-dot"}
                         id={"circle-dot-need"}/>
                    <div className={"need-background-info"}
                         id={"need-info"}>
                             Ethanol is becoming increasingly important because it can be made from easily available grains, which boosts national income and supports the agricultural sector. The government's ethanol blending program (EBP) promotes ethanol production and use, helping to reduce environmental impact, decrease reliance on fossil fuels, and support renewable energy initiatives​.
                    </div>
                </div>
                <div className={"need-background-div-green"} id={"need-for-project-green"}/>
            </div>
        </div>
    </>
}
