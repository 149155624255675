import './App.css';
import NavBar from "./components/NavBar/NavBar";
import AboutUsPage from "./components/AboutUsPage/AboutUsPage";
import {Route, Switch} from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import ContactUsPage from "./components/ContactUsPage/ContactUsPage";
import Footer from "./components/Footer/Footer";
// import WhyEthanolPage from "./components/WhyEthanolPage/WhyEthanolPage";
import LoadingAnimation from "./components/LoadingAnimation/LoadingAnimation";

// const ExtraDiv = styled.div`
//   width: 100%;
//   height: 7vh;
//   overflow-x: hidden;
// `

function App() {
    return (
        <div className="App">
            <LoadingAnimation/>
            <NavBar/>
            <Switch>
                <Route exact path={"/"} component={LandingPage}/>
                <Route exact path={"/aboutus"} component={AboutUsPage}/>
                <Route exact path={"/contactus"} component={ContactUsPage}/>
                {/*<Route exact path={"/whyethanol"} component={WhyEthanolPage}/>*/}
            </Switch>
            <Footer/>
            {/*<ExtraDiv/>*/}
        </div>
    );
}

export default App;
