import React from "react";
import "./TopSection.css";

export default function TopSection() {
    return <>
        <div className={"top-section-main-container"} id={"about-us-top-section"}>
            {/*<img src={industryImage} id={"about-us-image"} alt={"industry"}/>*/}
            <span id={"about-us-text-heading"}>About Us</span>
            <div id="zircon-info">
                M/S Zircon Advance Fuels Private Limited is a company incorporated under the Companies Act, 1956 with
                the office of Registrar of Companies, Madhya Pradesh & on 17/07/2021 and having CIN No.
                U15490MP2021PTC056730.
                <br/>
                <br/>
                The company aims to set up a 190 KLPD Grain Based distillery at Khasra No. 282,
                Village Kundla, Tehsil Agar, Dist. Agar Malwa, 465441, Madhya Pradesh, India
            </div>
        </div>
    </>
}