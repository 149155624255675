import React, {useEffect, useMemo, useState} from "react";
import {GoogleMap, InfoWindow, LoadScript, Marker} from "@react-google-maps/api";
import styled from "styled-components";
import NavigationIcon from '@mui/icons-material/Navigation';


const MapMainContainer = styled.div`
  width: 90%;
  margin: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`

const SelectLocationContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`
const SelectBox = styled.select`
  background: #2E8764;
  border: 3px solid #F3F3F3;
  color: #FFFFFF;
  font-weight: bold;
  padding: 10px 20px 10px 5px;
  border-radius: 0;
  font-size: 14px;
  outline: none;
  stroke: none;
  position: relative;
  top: -4.5rem;

  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`

const InfoWindowContentContainer = styled.div`
  //padding: 10px;
  display: flex;
  flex-direction: column;


  p {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    color: #2E8764;
  }

  a {
    color: #0060B6;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin: 5px 0;
  }

  a:hover {
    color: #00A0C6;
    text-decoration: none;
    cursor: pointer;
  }

`
export default function MapsSection() {

    const [selected, setSelected] = useState({});

    const [locationValue, setLocationValue] = useState("registered_office");

    const mapStyles = {
        height: "500px",
        width: "100%"
    };

    const locations = useMemo(() => [

        {
            name: "Industry Location",
            location: {
                lat: 23.69394533226188,
                lng: 76.06072780345409
            },
        },

        {
            name: "Registered Office",
            location: {
                lat: 23.718901955002078,
                lng: 76.00289813056493
            },


        },
        {
            name: "Corporate Office",
            location: {
                lat: 23.69394533226188,
                lng: 76.06072780345409
            },
        },
        {
            name: "Branch Office",
            location: {
                lat: 22.723337874059382,
                lng: 75.88747354357116
            },
        },

    ], []);

    const [centerValue, setCenterValue] = useState(locations[1]);

    function handleLocationSelectChange(event) {
        const value = event.target.value;
        setLocationValue(value)

        console.log(value)

    }

    const onSelect = item => {
        setSelected(item);
    }

    function createNavigationLink(lat, lng) {
        // if ((navigator.platform.indexOf("iPhone") !== -1)
        //     || (navigator.platform.indexOf("iPod") !== -1)
        //     || (navigator.platform.indexOf("iPad") !== -1))
        //     return `maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`;
        // else
        //     return `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`
        return `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`
    }

    // maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=23.69394533226188,76.06072780345409
    // https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=23.69394533226188,76.06072780345409


    useEffect(() => {
        switch (locationValue) {
            case "industry_office":
                setSelected(locations[0]);
                setCenterValue(locations[0].location);
                break;
            case "registered_office":
                setSelected(locations[1]);
                setCenterValue(locations[1].location);
                break;
            case "corporate_office":
                setSelected(locations[2]);
                setCenterValue(locations[2].location);
                break;
            case "branch_office":
                setSelected(locations[3]);
                setCenterValue(locations[3].location);
                break;
            default:
                setSelected(locations[0]);
                setCenterValue(locations[0].location);
                break;
        }
    }, [locationValue, locations])

    return <MapMainContainer>
        <LoadScript
            // googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            googleMapsApiKey={"AIzaSyClU71ojM9pWACbS8cZLSNg1MBtqZNFva8"}>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={centerValue}>
                {
                    locations.map(item => {
                        return (
                            <Marker key={item.name}
                                    position={item.location}
                                    onClick={() => onSelect(item)}/>
                        )
                    })
                }

                {

                    selected.location &&
                    (
                        <InfoWindow
                            position={selected.location}
                            clickable={true}
                            onCloseClick={() => setSelected({})}>
                            <InfoWindowContentContainer>
                                <p>{selected.name}</p>
                                <a href={createNavigationLink(selected.location.lat, selected.location.lng)}
                                   target={"_blank"} rel="noreferrer">
                                    Navigate <NavigationIcon/>
                                </a>
                            </InfoWindowContentContainer>
                        </InfoWindow>
                    )
                }

            </GoogleMap>
        </LoadScript>

        <SelectLocationContainer>
            <SelectBox value={locationValue} onChange={handleLocationSelectChange}>
                <option value={"industry_location"}>Industry Location</option>
                <option value={"registered_office"}>Registered Office</option>
                <option value={"corporate_office"}>Corporate Office</option>
                <option value={"branch_office"}>Branch Office</option>
            </SelectBox>
        </SelectLocationContainer>
    </MapMainContainer>
}