import React, {useEffect} from "react";
import "./ProjectBackground.css";
import {useInView} from "react-intersection-observer";
import useScrollDirection from "../../useScrollDirection";

export default function ProjectBackground() {

    const {scrollUp, scrollDown} = useScrollDirection();
    const {ref, inView} = useInView();

    useEffect(() => {
        if (inView && scrollDown) {
            document.getElementById("project-background-info").classList.add("active")
            document.getElementById("circle-dot-project").classList.add("active")
        } else if (!inView && scrollUp) {
            document.getElementById("project-background-info").classList.remove("active")
            document.getElementById("circle-dot-project").classList.remove("active")
        }
    }, [inView, scrollDown, scrollUp])

    return <>
        <div id={"project-background-main-container"}>
            <div id={"project-background-heading-container"}>
                <span className={"project-background"}>Project</span>
                <span className={"project-background"}>Background</span>
            </div>
            <div id={"project-background-info-container"}>
                <div className={"need-background-div-green"} id={"project-background-green"}/>
                <div className={"need-background-div"}
                     id={"project-background-info"}
                     ref={ref}>
                    <div className={"circle-dot"}
                         id={"circle-dot-project"}/>
                    <div className={"need-background-info"} id={"project-info"}>
                             The grain-based ethanol project addresses the need for sustainable energy. Utilizing locally available grains, the project leverages biofuel technology for efficient, eco-friendly production. Collaborating with farmers and experts, we aim to meet rising ethanol demand, supported by the government's EBP program, contributing to economic growth and energy security.
                    </div>
                </div>
            </div>
        </div>
    </>
}
