import React from "react";
import "./AddressContainer.css";
import LocationOnIcon from '@mui/icons-material/LocationOn';


function createNavigationLink(lat, lng) {
    // if ((navigator.platform.indexOf("iPhone") !== -1)
    //     || (navigator.platform.indexOf("iPod") !== -1)
    //     || (navigator.platform.indexOf("iPad") !== -1))
    //     return `maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`;
    // else
    //     return `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`

    return `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`
}

function AddressCard(props) {
    return <div className={"address-card-container"}>
        <span className={"address-heading"}>{props.heading} <a
            href={createNavigationLink(props.location.lat, props.location.lng)}
            target={"_blank"} rel="noreferrer"><LocationOnIcon fontSize={"medium"}/> </a></span>
        <span className={"address-text"}>{props.address}</span>
    </div>
}


export default function AddressContainer() {
    return <>
        <div id={"address-main-container"}>
            <AddressCard
                heading={"Corporate Office"}
                address={"Khasra No. 282, Village Kundla, Tehsil Agar, Dist. Agar Malwa, 465441, Madhya Pradesh, India"}
                location={{lat: 23.69394533226188, lng: 76.06072780345409}}/>
            <AddressCard
                heading={"Registered Office"}
                address={"119/3, Barod Road, Agar Malwa, Madhya Pradesh, 465441, India"}
                location={{lat: 23.718901955002078, lng: 76.00289813056493}}/>
            <AddressCard
                heading={"Industry Location"}
                address={"Khasra No. 282, Village Kundla, Tehsil Agar, Dist. Agar Malwa, 465441, Madhya Pradesh, India"}
                location={{lat: 23.69394533226188, lng: 76.06072780345409}}/>
            <AddressCard
                heading={"Branch Office"}
                address={"19/1, Manorama Ganj, 4th Floor, Near Palasia Square, Indore, 452001, Madhya Pradesh, India"}
                location={{lat: 22.723337874059382, lng: 75.88747354357116}}/>
        </div>
    </>
}