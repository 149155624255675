import React from "react";
import "./TopSection.css";
import phone from "../../../assets/ContactUsPage/phone.svg";
import contact from "../../../assets/ContactUsPage/contact-book.svg";
import {motion} from "framer-motion";

export default function TopSection() {

    return <div id={"contact-us-top-section"}>
        <div id={"contact-us-top-section-main-container"}>
            <div id={"contact-us-top-background"}>
                <div className={"contact-us-heading-text"}>
                    <motion.img id={"phone-img"}
                                src={phone}
                                alt={"phone"}
                                animate={{rotate: [-20, 20, -15, 15, -10, 10, -5, 5, -2.5, 2.5, 0]}}
                                transition={{
                                    type: "spring",
                                    duration: 0.5,
                                    bounce: 2,
                                }}
                    />
                    <span id={"contact-text"}>ontact Us</span>
                </div>
                <img id={"top-right-img"} src={contact} alt={"phone"}/>
                <img id={"bottom-left-img"} src={contact} alt={"phone"}/>
            </div>
        </div>
    </div>
}