// import {useCallback, useEffect, useState} from 'react';
//
// export default function useScrollDirection() {
//     const [scrollUp, setScrollUp] = useState(false);
//     const [scrollDown, setScrollDown] = useState(true);
//
//     const handleScroll = useCallback((event) => {
//         if (event.wheelDelta > 0) {
//             setScrollUp(true);
//             setScrollDown(false);
//         } else {
//             setScrollUp(false);
//             setScrollDown(true);
//         }
//     }, [])
//
//     useEffect(() => {
//         window.addEventListener('mousewheel', handleScroll);
//         return () => {
//             window.removeEventListener('mousewheel', handleScroll);
//         };
//     }, [handleScroll]);
//
//     return {scrollUp, scrollDown};
// }


import {useCallback, useEffect, useState} from 'react';

export default function useScrollDirection() {
    const [scrollUp, setScrollUp] = useState(false);
    const [scrollDown, setScrollDown] = useState(true);

    const [yOffset, setYOffset] = useState(0);

    const handleScroll = useCallback(() => {
        let st = window.pageYOffset || document.documentElement.scrollTop;


        if (st < yOffset) {
            setScrollUp(true);
            setScrollDown(false);
        } else {
            setScrollUp(false);
            setScrollDown(true);
        }

        const newValue = st <= 0 ? 0 : st;
        setYOffset(newValue);
    }, [yOffset])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, false);
        return () => {
            window.removeEventListener('scroll', handleScroll, false);
        };
    }, [handleScroll]);

    return {scrollUp, scrollDown};
}