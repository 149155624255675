import React from "react";
import styled from "styled-components";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import muiStyle from '@mui/material/styles/styled';

const MyPhoneIcon = muiStyle(PhoneIcon)({
    color: 'green',
    margin: "8px",
    fontSize: "18px",
});

const MyEmailIcon = muiStyle(EmailIcon)({
    color: 'green',
    margin: "8px",
    fontSize: "18px",
});

const HelpYouOutMainDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin: 5%;
  flex-wrap: wrap;
`;

const HelpYouOutBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 3px solid #2E8764;

  width: 400px;
  max-width: 100%;
  min-height: 160px;

  padding: 20px 10px;
  margin: 20px 10px;

  span {
    font-weight: bold;
  }

  a, p {
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  a {
    color: #0060B6;
    text-decoration: none;
  }

  a:hover {
    color: #00A0C6;
    text-decoration: none;
    cursor: pointer;
  }
`

export default function HelpYouOutContainer() {
    return <HelpYouOutMainDiv>
        <HelpYouOutBox>
            <span>We're here to help you out.</span>
            <p>
                If you have any questions, just reach out to us and we’ll respond as soon as we can. Please provide as
                much information as possible.
            </p>
        </HelpYouOutBox>
        <HelpYouOutBox>
            <span>You can also contact us through:</span>
            <a href={"mailto:info@zirconadvance.com"}><MyEmailIcon/> info@zirconadvance.com</a>
            <a href={"tel:+917314055505"}><MyPhoneIcon/>+917314055505</a>
        </HelpYouOutBox>
    </HelpYouOutMainDiv>
}